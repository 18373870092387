<template>
  <vuestic-widget :loading="loading" class="releasenote-create-page">
    <template slot="header">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <span class="title">Edit Note #{{ formData.id }}</span>
        <div class="d-flex justify-content-end align-items-center">
        </div>
      </div>
    </template>
    <ValidationObserver v-slot="{ handleSubmit, invalid, dirty }" ref="updateForm">
      <form @submit.prevent="handleSubmit(onFormSubmit)" class="updateForm narrow-form1">
        <div class="row mt-4">
          <div class="col-md-12">
            <div class="form-group">
              <div class="input-group">
                <label class="control-label">Date</label>
                <vuestic-date-picker id="scheduled_at_day_picker" v-model="formData.releaseAt"
                  :config="datePickerConfig" />
                <i class="bar"></i>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <text-input name="Title" v-model="formData.title" placeholder="Title" label="Title" validate="required" />
          </div>
          <div class="col-md-12 mt-4">
            <label class="lelabel">Content</label>
            <textarea ref="editor"></textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 mt-4">
            <div class="lelabel my-2">Active</div>
            <vuestic-switch v-model="formData.active" :offcolor=true>
              <span slot="trueTitle">ON</span>
              <span slot="falseTitle">OFF</span>
            </vuestic-switch>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-12 d-flex justify-content-center">
            <router-link class="btn btn-primary btn-danger mr-2" :to="{ name: 'admin.releaseNote.index' }">
              <span>Back</span>
            </router-link>
            <button class="btn btn-primary" :disabled="invalid || processing" style="min-width: 80px;">
              <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF"
                style="margin: auto;" />
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </vuestic-widget>
</template>
    
<script>
import { mapState } from 'vuex';
import TimeSelector from "../../forms/TimeSelector";

// froala-editor
import FroalaEditor from 'froala-editor'
import 'froala-editor/js/plugins.pkgd.min.js'
import 'froala-editor/css/froala_editor.pkgd.min.css'
import 'froala-editor/css/froala_style.min.css'

export default {
  components: {
    TimeSelector,
  },
  data() {
    return {
      loading: false,
      processing: false,
      datePickerConfig: {
        enableTime: false,
        dateFormat: "Y-m-d",
      },
      formData: {
        title: '',
        content: '',
        releaseAt: undefined,
        active: true,
      },
      editor: null,
      editorInitialized: false,
      editorConfig: {
        key: process.env.VUE_APP_FROALA_API_KEY,
        heightMin: 300,
        heightMax: 500,
        quickInsertEnabled: false,
        toolbarVisibleWithoutSelection: false,
        useClasses: false,
        enter: FroalaEditor.ENTER_BR,
        fontFamilySelection: true,
        fontSizeSelection: true,
        linkAlwaysBlank: true,
        linkStyles: {
          'fr-green': 'Green',
          'fr-strong': 'Thick',
          'underlineClass': 'Underline',
        },
        toolbarButtons: {
          'moreText': {
            'buttons': [
              'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'bold', 'italic', 'underline', 'alignLeft', 
              'alignCenter', 'alignRight', 'alignJustify', 'insertLink', 'insertImage', 'emoticons', 'formatUL', 'formatOL', 
              'lineHeight', 'strikeThrough', 'paragraphFormat', 'paragraphStyle', 'insertHR', 'outdent', 'indent', 'quote', 'undo', 'redo', 'fullscreen', 'html'
            ],
            'buttonsVisible': 10,
          },
        },
        emoticonsUseImage: false,
        requestHeaders: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        },
        imageUpload: true,
        imageUploadMethod: 'POST',
        imageUploadParam: 'image',
        imageUploadParams: {
          link: true,
        },
        imageUploadRemoteUrls: true,
        imageUploadURL: process.env.VUE_APP_BASE_API + 'api/v1/image',
        ...this.options,
        events: {
          initialized: () => {
            this.editor.html.set(this.formData.content)
            this.initEvents()
          },
          blur: () => { 
            this.editor.selection.save();
          },
          destroy: () => { }
        },
      }
    };
  },

  created() {
  },

  computed: {
  },
  
  mounted() {
    if (this.$route.params.id) {
      const id = this.$route.params.id
      this.loadData(id);
    } else {
      this.redirectOnError()
    }
  },
  methods: {
    initEditor() {
      if (!this.editorInitialized) {
        this.editor = new FroalaEditor(this.$refs.editor, this.editorConfig)
        this.editorInitialized = true
      }
    },
    initEvents() {
      let _this = this
      _this.editor.events.on('contentChanged', function (e) {
        if (_this.formData.content !== _this.editor.html.get()) {
          _this.formData.content = _this.editor.html.get()
        }
      }, false);

    },
    redirectOnError() {
      this.$router.push({ name: 'admin.users.index' });
    },

    loadData(id) {
      this.loading = true;
      this.$store
        .dispatch('releaseNote/get', id)
        .then((res) => {
          this.loading = false;
          this.initData(res.data);
        })
        .catch((err) => {
          this.loading = false;
          this.redirectOnError()
        })
    },

    initData(data) {
      this.formData = 
      {
        id: data.id,
        title: data.title,
        content: data.content,
        releaseAt: data.released_at,
        active: data.active,
      }
      this.$nextTick(() => this.initEditor());
    },

    onFormSubmit() {
      const param = {
        id: this.formData.id,
        data: {
          ...this.formData, 
        }
      }
      this.processing = true
      this.$store
        .dispatch('releaseNote/update', param)
        .then(() => {
          this.processing = false
        })
        .catch((err) => {
          this.processing = false
        })
    },
  },

};
</script>
    
<style lang="scss" scoped>
.vuestic-switch {
  max-width: 200px;
  width: 200px;
}
</style>